import React, {useContext, useEffect, useState} from 'react';

import LegacyUser, {LegacyUserEvents} from '@deezer/legacy-user';
import type {Child} from '@deezer/legacy-user';
import {useApiContext} from '@deezer/react-legacy-api';

const UserContext = React.createContext<LegacyUser | undefined>(undefined);

let userServiceInstance: LegacyUser;

const UserProvider: React.FC<{
	children?: React.ReactNode;
	pictureDomain: string;
}> = ({children, pictureDomain}) => {
	const legacyApi = useApiContext();

	if (!userServiceInstance) {
		userServiceInstance = new LegacyUser(legacyApi, pictureDomain);
	}

	useEffect(() => {
		userServiceInstance.initialize();
	}, []);

	return (
		<UserContext.Provider value={userServiceInstance}>
			{children}
		</UserContext.Provider>
	);
};

export default UserProvider;

export const useUserContext = (): LegacyUser => {
	const ctxUserService = useContext(UserContext);

	if (!ctxUserService) {
		throw new Error('useUserContext must be used whithin a UserProvider');
	}

	return ctxUserService;
};

export const useUser = (): readonly [LegacyUser['user'] | null] => {
	const ctxUserService = useUserContext();

	const [user, setUser] = useState<null | LegacyUser['user']>(() => {
		try {
			return ctxUserService.user;
		} catch (e) {
			return null;
		}
	});

	useEffect(() => {
		const setUserFromService = () => {
			setUser(ctxUserService.user);
		};

		ctxUserService.addEventListener(
			LegacyUserEvents.USER_CHANGED,
			setUserFromService,
		);

		return () => {
			ctxUserService.removeEventListener(
				LegacyUserEvents.USER_CHANGED,
				setUserFromService,
			);
		};
	}, [ctxUserService]);

	return [user] as const;
};

/**
 * @deprecated This hook should only be used if you need access to currently untyped unformated getUserData information
 */
export const useLegacyUser = (): readonly [null | LegacyUser['legacyUser']] => {
	const ctxUserService = useUserContext();
	const [user, setUser] = useState<null | LegacyUser['legacyUser']>(() => {
		try {
			return ctxUserService.legacyUser;
		} catch (e) {
			return null;
		}
	});

	useEffect(() => {
		const setUserFromService = () => {
			setUser(ctxUserService.legacyUser);
		};

		ctxUserService.addEventListener(
			LegacyUserEvents.USER_CHANGED,
			setUserFromService,
		);

		return () => {
			ctxUserService.removeEventListener(
				LegacyUserEvents.USER_CHANGED,
				setUserFromService,
			);
		};
	});

	return [user] as const;
};

let userChildCache: [string, Child[]] = ['', []];

export const useFamilyUser = (): readonly [
	LegacyUser['user'] | null,
	Child[],
	{
		readonly switchFamilyProfile: (userId: string) => Promise<void>;
	},
] => {
	const ctxUserService = useUserContext();
	const [user] = useUser();
	const [children, setChildren] = useState<Child[]>(
		userChildCache[0] === user?.id ? userChildCache[1] : [],
	);

	useEffect(() => {
		if (user) {
			ctxUserService.fetchChildren().then((childs) => {
				setChildren(childs);
				userChildCache = [user.id, childs];
			});
		}
	}, [ctxUserService, user]);

	return [
		user,
		children,
		{
			switchFamilyProfile:
				ctxUserService.switchFamilyProfile.bind(ctxUserService),
		},
	] as const;
};
