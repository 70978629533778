import React, {useContext} from 'react';

import LegacyApi from '@deezer/legacy-api';

const ApiContext = React.createContext<LegacyApi | undefined>(undefined);

let uniqueApiInstance: LegacyApi;

const ApiProvider: React.FC<
	{
		children?: React.ReactNode;
		host: ConstructorParameters<typeof LegacyApi>[0];
	} & Partial<ConstructorParameters<typeof LegacyApi>[1]>
> = ({children, host, ...options}) => {
	if (!uniqueApiInstance) {
		uniqueApiInstance = new LegacyApi(host, options);
	}

	return (
		<ApiContext.Provider value={uniqueApiInstance}>
			{children}
		</ApiContext.Provider>
	);
};

export default ApiProvider;

export const useApiContext = (): LegacyApi => {
	const ctxApiService = useContext<LegacyApi | undefined>(ApiContext);

	if (!ctxApiService) {
		throw new Error('useApiContext must be used whithin a ApiProvider');
	}

	return ctxApiService;
};

export const useApiCall = (): LegacyApi['call'] => {
	const ctxApiService = useApiContext();

	return ctxApiService.call.bind(ctxApiService);
};
