import React from 'react';

import UserProvider from '@deezer/react-legacy-user';
import ApiProvider from '@deezer/react-legacy-api';
import ThemeProvider from '@providers/ThemeProvider';

import {config} from '@modules/config';

// Note: this is the implementation for a single shared layout
// If you need multiple layouts see https://nextjs.org/docs/basic-features/layouts#per-page-layouts

const AppRoot: React.FC<{children?: React.ReactNode}> = ({children}) => (
	<ApiProvider
		host={config.get('host_website_ssl')}
		mode="cors"
		credentials="include"
	>
		<UserProvider pictureDomain={config.get('host_img_url_ssl')}>
			<ThemeProvider>{children}</ThemeProvider>
		</UserProvider>
	</ApiProvider>
);

export default AppRoot;
