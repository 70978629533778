import React from 'react';

import {i18n} from 'next-i18next';

import {
	ColorModeScript,
	extendTheme,
	createLocalStorageManager,
	TempoProvider,
} from '@tempo/core';
import {useRouter} from 'next/router';

const STORAGE_KEY = 'tempo-color-mode';
const localStorageManager = createLocalStorageManager(STORAGE_KEY);

const getDirection = (locale: string): 'rtl' | 'ltr' => {
	return i18n?.dir(locale) || 'ltr';
};

const ThemeProvider: React.FC<{
	children?: React.ReactNode;
}> = ({children}) => {
	const {locale} = useRouter();

	const direction = getDirection(locale || 'en');

	const theme = extendTheme({
		config: {
			direction,
			initialColorMode: 'light',
		},
	});

	React.useEffect(() => {
		document.querySelector('body')?.setAttribute('dir', direction);
	}, [direction]);

	return (
		<>
			<ColorModeScript
				initialColorMode={theme.config.initialColorMode}
				storageKey={STORAGE_KEY}
			/>
			<TempoProvider colorModeManager={localStorageManager} theme={theme}>
				{children}
			</TempoProvider>
		</>
	);
};

export default ThemeProvider;
